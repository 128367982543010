import * as React from 'react';
import { NextPage } from 'next';
import nookies from 'nookies';
import { Login } from '../src/components/Login';
import { Layout } from '../src/components/Layout/';
import { AMPLITUDE_PAGES } from '@/modules/amplitude/events';

interface LoginProps {
  pathToRedirect?: string;
}

// export async function getServerSideProps(ctx) {
//   const pathToRedirect = nookies.get(ctx).pathToRedirect || '/dashboard';

//   return { props: { pathToRedirect } };
// }

const Page: NextPage<LoginProps> = ({ pathToRedirect }) => {
  const site = {
    title: `Login`,
    header: 'TBA',
    description: 'Discover lit events.',
    pathToRedirect,
  };
  return (
    <Layout pageName={AMPLITUDE_PAGES.LOGIN_PAGE} data={site}>
      <Login pathToRedirect={pathToRedirect} />
    </Layout>
  );
};
export default Page;
